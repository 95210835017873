import { getStore, setStore } from "@/utils/common.js";
// 订单相关状态  0待支付/未支付 1待发货/已付款 2待收货 3待评价 4已取消，5已评价，6交易关闭（超时未支付）
const ORDER_STATE = {
  ALL: {
    name: "全部",
    state: -1
  },
  PAY: {
    name: "待付款",
    state: 0
  },
  DELIVER: {
    name: "等待卖家发货",
    state: 1
  },
  TAKE: {
    name: "卖家已发货",
    state: 2
  },
  EVALUATE: {
    name: "待评价",
    state: 3
  },
  CANCEL: {
    name: "已取消",
    state: 4
  },
  EVALUATE_ED: {
    name: "已评论",
    state: 5
  },
  CLOSE: {
    name: "交易关闭",
    state: 6
  },
  NO_SALE: {
    name: "售后截止",
    state: 7
  },
  GROUP_WAIT: {
    name: "待成团",
    state: 8
  },
  DISPOSE: {
    name: "取消处理中",
    state: 9
  },
  PAYING: {
    name: "支付处理中",
    state: 10
  },
  PENDING: {
    name: "待审批",
    state: 11
  },
  Approvalfailed: {
    name: "审批失败",
    state: 12
  },
  PendingShipment: {
    name: "发货处理中",
    state: 15
  },
  ThirdPartyIsTrueNo: {
    name: "三方确认失败",
    state: 16
  }
};

// 售后相关状态
const SALE_STATE = {
  SALE_DEAL: {
    name: "待处理",
    state: 1
  },
  SALE_ING: {
    name: "处理中",
    state: 2
  },
  SALE_FINISH: {
    name: "已完成",
    state: 3
  },
  SALE_REFUSE: {
    name: "已拒绝",
    state: 4
  }
};

// 订单列表tab
const ORDER_TAB = [
  { stateName: "全部", key: "ALL" },
  { stateName: "待付款", key: "PAY" },
  { stateName: "待发货", key: "DELIVER" },
  { stateName: "待收货", key: "TAKE" },
  { stateName: "待评价", key: "EVALUATE" }
];

// 售后列表tab
const AFTER_SALE_TAB = [
  { stateName: "待处理", key: "SALE_DEAL" },
  { stateName: "处理中", key: "SALE_ING" },
  { stateName: "已完成", key: "SALE_FINISH" },
  { stateName: "已拒绝", key: "SALE_REFUSE" }
];
// 1: 普通商品订单，  2：售后订单
function _getOrderObj(orderType = 1) {
  return orderType == 1 ? ORDER_STATE : SALE_STATE;
}

const SOURCE_TYPE = new Map([
  [1, "精选"],
  [2, "自营"],
  [3, "自营"],
  [4, "京东"],
  [5, "苏宁"],
  [6, "虚拟"],
  [7, "全球美妆"],
  [8, "虚拟"],
  [9, "办公"],
  [10, "粮油"],
  [11, "美妆"],
  [12, "虚拟"],
  [100, "基础运费"],
  [1000, "统一运费"]
]);

export const orderMixin = {
  methods: {
    // 根据 订单 id筛选订单文本
    getOrderState(type_id) {
      let ORDER_TYPE = getStore("ORDER_TYPE");
      let obj = _getOrderObj(ORDER_TYPE);
      console.log(obj, "obj");
      for (let k in obj) {
        if (obj[k].state == type_id) {
          return obj[k].name;
        }
      }
    },
    // 通过订单 内容 返回订单状态
    getOrderId(key) {
      let obj = ORDER_STATE[key] ? ORDER_STATE : SALE_STATE;
      return obj[key].state;
    },
    // 根据订单 id 返回订单状态 key.
    getOrderKey(type_id) {
      let ORDER_TYPE = getStore("ORDER_TYPE");
      let obj = _getOrderObj(ORDER_TYPE);
      for (let k in obj) {
        if (obj[k].state == type_id) {
          return k;
        }
      }
    },
    // 根据key 返回订单列表顶部tab
    getTabList(key) {
      let Index = ORDER_TAB.findIndex(item => item.key === key);
      if (Index > -1) {
        setStore("ORDER_TYPE", 1);
        return ORDER_TAB;
      } else {
        this.currentKey = AFTER_SALE_TAB[0].key;
        setStore("ORDER_TYPE", 2);
        return AFTER_SALE_TAB;
      }
    },
    // 根据source_type 返回商品来源 1 B1自营，2B2自营，3B3自营，4京东，5苏宁，6虚拟商品，7全球美妆商品，8虚拟商品
    getSourceType(order_items) {
      if (!order_items[0]) return;
      let source_type = order_items[0].source_type;
      const str = SOURCE_TYPE.get(source_type);
      return str;
    },

    getSourceTypes(source_type) {
      if (!source_type) return;
      const str = SOURCE_TYPE.get(source_type);
      return str;
    },

    // 是否为虚拟商品，可用于虚拟商品特殊处理
    getVirtualStatus(source_type) {
      const back = this.getSourceTypes(source_type);
      if (back === "虚拟") {
        return false;
      } else {
        return true;
      }
    }
  }
};

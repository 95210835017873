<template>
  <div class="exchange-detail">
    <div class="detail-back">
      <div class="back-cont">
        <img src="@/assets/point_detail.png" alt="">
        <!-- <span class="txt">{{getOrderState(order_status) ? '兑换成功' : '待支付'}}</span> -->
        <div class="txt" v-if="getOrderState(order_status)=='待付款'&&type==3&&item.is_password==0">待提交</div>
        <div class="txt" v-else-if="getOrderState(order_status)=='待付款'&&type==3&&item.is_password==1">待审批</div>
        <div class="txt" v-else>{{getOrderState(order_status)}}</div>
      </div>
    </div>

    <div class="detail-cont">
      <div class="detail-item">
        <div class="layout-bottom">
          <div class="img">
            <img :src="order_detail.base_pic" alt="">
          </div>
          <div class="img-txt">
            <p class="name">{{order_detail.item_name}}</p>
            <p class="number">&times;{{order_detail.number}}</p>
          </div>
        </div>
        <p class="item-cont">
          <span>所需会员积分</span>
          <span>{{this.order_detail.total_integral}}</span>
        </p>
        <p class="item-cont"  :class="{'border': !Number(order_status)}">
          <span>下单时间</span>
          <span>{{this.order_detail.create_time | formatDateTime}}</span>
        </p>
         <p v-if="Number(order_status) && this.order_detail.pay_time" class="item-cont" :class="{'border': Number(order_status)}">
          <span>支付时间</span>
          <span>{{this.order_detail.pay_time | formatDateTime}}</span>
        </p>
         <p class="item-cont">
          <span>兑换订单号</span>
          <span>{{this.order_detail.order_sn}}</span>
        </p>
      </div>

      <div v-if="Number(order_detail.recharge_type) === 1 && Number(order_status) && order_detail.kami" class="account">
        <span class="txt">{{source_type}}</span>
        <div class="number">{{order_detail.kami}}</div>
      </div>
        <div v-if="Number(order_detail.recharge_type) === 2" class="account">
        <span class="txt">{{source_type}}</span>
        <div class="number">{{order_detail.recharge_account}}</div>
      </div>
    </div>

    <div v-if="!Number(order_status)" class="order-btn">
      <div class="confirm common" @click.stop="selectPay()">
        支付积分
      </div>
      <div class="cancel common" @click="cancelOrder()">
        取消订单
      </div>
    </div>
    <div v-if="Number(order_status) === 2" class="order-btn">
      <div class="confirm common" @click.stop="confirmReceipt()">
        确认收货
      </div>
    </div>

    <confirm-alert
      :value="show_toast"
      :is_delete="true"
      :confirmText="confirmText"
      confirmBtnText="确认取消"
      @cancel="cancel"
      @confirm="confirm"
    ></confirm-alert>

    <!-- 安全键盘 -->
    <yd-keyboard v-model="show_keyboard" :callback="checkPwd" disorder ref="keyboard" title></yd-keyboard>
  </div>
</template>

<script>
import { payOrder, cancelOrder, getExchangeInfo, confirmReceipt } from "@/services/orderApi.js";
import {getStore, setStore} from "@/utils/common.js";
import {mapState} from 'vuex';
import md5 from "js-md5";
import { baseUrl } from "@/utils/env";
import {orderMixin} from "@/mixins/orderMixin.js";
import ConfirmAlert from "@/components/common/ConfirmAlert"
import { debounce } from "@/utils/debounce";

export default {
  name: 'exchange_detail',
  mixins: [orderMixin],
  data() {
    return {
      order_id: this.$route.query.order_id || 0,
      order_detail: [],
      order_status: this.$route.query.order_status,
      show_keyboard: false,
      source_type: '',
      show_toast: false,
      alertType: 1, // 弹窗的类型： 1：取消订单  2：取消申请售后
      confirmText: "您确定要取消订单么？",
      pay_order_id: this.$route.query.pay_order_id || 0  //  母单id
    }
  },
  components: {
    ConfirmAlert
  },
  mounted() {
    this.getExchangeInfo()
    console.log('id', this.pay_order_id)
  },
  computed: {
    ...mapState({
      type: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      integralRate: state => state.config.integral_rate, // 积分比例
      pay_password: state => !!state.userInfo.pay_password //是否设置支付密码
    })
  },
  methods: {

     //  确认收货
    async confirmReceipt(item) {
      let id = this.order_id
      try {
        if (id) {
          const res = await confirmReceipt(id);
          if (res.code === 0) {
            const data = res.data;
            console.log(data);
            this.$dialog.toast({
              mes: "确认收货成功"
            });
            this.$router.push({
              name: "confirm_success"
            });
          } else {
            this.$dialog.toast({
              mes: res.msg
            });
          }
        }
      } catch (e) {}
    },
    async getExchangeInfo() {
      try {
        let data;
        data = await getExchangeInfo(this.order_id, this.pay_order_id);
        if (data.code === 0) {
          this.order_detail = data.data;
          this.order_status = data.data.order_status
          getStore('ORDER_TYPE') ? '' : setStore('ORDER_TYPE', 1)
          this.ORDER_TYPE = getStore("ORDER_TYPE");
          this.pay_order_id = data.data.pay_order_id
          this.order_detail.recharge_type === 1 ? this.source_type = '兑换卡密' : this.source_type = '充值账号'
        }
      } catch (err) {
        throw err;
      }
    },

    //  验证支付密码
    checkPwd(val) {
      let self = this;
      console.log("输入的密码是：" + val + " - 无序");
      self.$dialog.loading.open("验证支付密码");
      self.payOrder(self.order_detail.pay_order_id, md5(val));
    },

    //支付密码
    toPayPwd(val) {
      this.show_keyboard = true;
    },

    //支付
    selectPay(item) {
      //1现金，2余额，3积分
      let payway = 3
	  let wxTotal = Number.parseFloat(this.order_detail.total_wx);
	  let intTotal = Number.parseFloat(this.order_detail.total_integral);
    console.log("payway" + payway);
     if (!this.pay_password) {
        //未设置支付密码
        this.no_pwd = true;
        return;
      } else if (this.pay_password) {
        //输入支付密码
        this.toPayPwd();
      }
    },

    //立即支付
    async payOrder(id, val) {
      let bid = window.localStorage.getItem("bid");
      const self = this;
      this.kunshan_toast = false;
      try {
        if (id) {
          const res = await payOrder(id, val);
          if (res.code === 0) {
            const data = res.data;
            self.$dialog.loading.close();
            console.log({baseUrl})
              window.location.replace(
                `${baseUrl}/?bid=${bid}#/pay_success?id=${id}`
              );
          } else {
            self.$dialog.loading.close();
            self.$refs.keyboard.$emit("ydui.keyboard.error", res.msg);
          }
        }
      } catch (e) {}
    },

    // 取消订单
    cancelOrder() {
        this.alertType = 1;
        this.confirmText = "您确定要取消订单么？";
        this.show_toast = true;
    },
    confirm: debounce(async function() {
      try {
        if (this.alertType == 1) {
          this.show_toast = false
          let data = await cancelOrder(this.pay_order_id);
          if (data.code === 0) {
            this.$dialog.toast({
              mes: "取消处理中",
              timeout: 1500,
              icon: "none",
              callback: () => {
                this.$router.go(-1);
              }
            });
          } else {
            console.log("取消失败", data);
            this.cancel();
            this.$dialog.toast({
              mes: data.msg,
              timeout: 1500,
              icon: "error"
            });
          }
        }
      } catch (err) {
        throw err;
      }
    }, 300),
    cancel() {
      this.show_toast = false;
      console.log(this.orderState,"orderState")
    },
  }
}
</script>

<style lang="less" scoped>
@import "~@/common/less/variable.less";
@import "~@/common/less/mixin.less";

.exchange-detail {

  .detail-back {
    .wh(100%, 2.4rem);
    background: var(--main-color);
    display: flex;
    justify-content: center;
    align-content: center;

    .back-cont {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 3rem;
      
      img {
        .wh(0.96rem, 0.96rem);
      }

      .txt {
        .sc(0.28rem, #fff)
      }
    }
  }

  .detail-cont {
    width: 100%;

    .item-cont {
      .wh(100%, 0.6rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sc(0.28rem, #666);
    }

    .account {
      .sc(0.28rem, #666);
      padding: 0 0.1rem;
      margin-top: 0.2rem;
      background: #fff;
      width: 100%;
      flex-wrap: wrap;
      overflow: hidden;

      .txt {
        float: left;
        padding: 0.2rem 0;
      }

      .number {
        float: right;
        word-wrap:break-word; 
        word-break:break-all; 
        // padding: 0.1rem 0;
        line-height: 0.6rem;
      }
    }

    .detail-item {
      padding: 0.1rem 0.1rem 0 0.1rem;
      background: #fff;

      .layout-bottom {
          position: relative;
          overflow: hidden;

          .img {
            float: left;

            img {
              .wh(0.96rem, 0.96rem)
            }
          }

          .img-txt {
            height: 0.96rem;
            margin-left: 0.2rem;
            float: left;
            position: relative;

            .number {
              .sc(0.24rem, #999);
              position: absolute;
              bottom: 0.05rem;
            }

            .name {
              .sc(0.28rem, #333);
              padding-top: 0.09rem;
            }
          }
      }

      .border {
        border-bottom: 0.01rem solid #BBB;
      }
    }
  }

  .order-btn {
    width: 100%;
    overflow: hidden;
    background: #fff;
    position: fixed;
    bottom: 0;

    .common {
      float: right;
      margin: 0.2rem 0;
      width: 2rem;
      height: 0.68rem;
      border-radius: 0.68rem;
      text-align: center;
      line-height: 0.68rem;
      font-size: 0.28rem;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: #fff;
      color: var(--main-color);
      border: 1px solid var(--main-color);
      margin-right: 0.3rem;
    }

    .concel {
      color: #666;
    }
    
    .confirm {
      background:  var(--main-color);
      color: #fff;
    }
  }
}
</style>
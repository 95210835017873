var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "exchange-detail" },
    [
      _c("div", { staticClass: "detail-back" }, [
        _c("div", { staticClass: "back-cont" }, [
          _c("img", {
            attrs: { src: require("@/assets/point_detail.png"), alt: "" },
          }),
          _vm.getOrderState(_vm.order_status) == "待付款" &&
          _vm.type == 3 &&
          _vm.item.is_password == 0
            ? _c("div", { staticClass: "txt" }, [_vm._v("待提交")])
            : _vm.getOrderState(_vm.order_status) == "待付款" &&
              _vm.type == 3 &&
              _vm.item.is_password == 1
            ? _c("div", { staticClass: "txt" }, [_vm._v("待审批")])
            : _c("div", { staticClass: "txt" }, [
                _vm._v(_vm._s(_vm.getOrderState(_vm.order_status))),
              ]),
        ]),
      ]),
      _c("div", { staticClass: "detail-cont" }, [
        _c("div", { staticClass: "detail-item" }, [
          _c("div", { staticClass: "layout-bottom" }, [
            _c("div", { staticClass: "img" }, [
              _c("img", { attrs: { src: _vm.order_detail.base_pic, alt: "" } }),
            ]),
            _c("div", { staticClass: "img-txt" }, [
              _c("p", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.order_detail.item_name)),
              ]),
              _c("p", { staticClass: "number" }, [
                _vm._v("×" + _vm._s(_vm.order_detail.number)),
              ]),
            ]),
          ]),
          _c("p", { staticClass: "item-cont" }, [
            _c("span", [_vm._v("所需会员积分")]),
            _c("span", [_vm._v(_vm._s(this.order_detail.total_integral))]),
          ]),
          _c(
            "p",
            {
              staticClass: "item-cont",
              class: { border: !Number(_vm.order_status) },
            },
            [
              _c("span", [_vm._v("下单时间")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatDateTime")(this.order_detail.create_time)
                  )
                ),
              ]),
            ]
          ),
          Number(_vm.order_status) && this.order_detail.pay_time
            ? _c(
                "p",
                {
                  staticClass: "item-cont",
                  class: { border: Number(_vm.order_status) },
                },
                [
                  _c("span", [_vm._v("支付时间")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatDateTime")(this.order_detail.pay_time)
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("p", { staticClass: "item-cont" }, [
            _c("span", [_vm._v("兑换订单号")]),
            _c("span", [_vm._v(_vm._s(this.order_detail.order_sn))]),
          ]),
        ]),
        Number(_vm.order_detail.recharge_type) === 1 &&
        Number(_vm.order_status) &&
        _vm.order_detail.kami
          ? _c("div", { staticClass: "account" }, [
              _c("span", { staticClass: "txt" }, [
                _vm._v(_vm._s(_vm.source_type)),
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.order_detail.kami)),
              ]),
            ])
          : _vm._e(),
        Number(_vm.order_detail.recharge_type) === 2
          ? _c("div", { staticClass: "account" }, [
              _c("span", { staticClass: "txt" }, [
                _vm._v(_vm._s(_vm.source_type)),
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.order_detail.recharge_account)),
              ]),
            ])
          : _vm._e(),
      ]),
      !Number(_vm.order_status)
        ? _c("div", { staticClass: "order-btn" }, [
            _c(
              "div",
              {
                staticClass: "confirm common",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.selectPay()
                  },
                },
              },
              [_vm._v("\n      支付积分\n    ")]
            ),
            _c(
              "div",
              {
                staticClass: "cancel common",
                on: {
                  click: function ($event) {
                    return _vm.cancelOrder()
                  },
                },
              },
              [_vm._v("\n      取消订单\n    ")]
            ),
          ])
        : _vm._e(),
      Number(_vm.order_status) === 2
        ? _c("div", { staticClass: "order-btn" }, [
            _c(
              "div",
              {
                staticClass: "confirm common",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.confirmReceipt()
                  },
                },
              },
              [_vm._v("\n      确认收货\n    ")]
            ),
          ])
        : _vm._e(),
      _c("confirm-alert", {
        attrs: {
          value: _vm.show_toast,
          is_delete: true,
          confirmText: _vm.confirmText,
          confirmBtnText: "确认取消",
        },
        on: { cancel: _vm.cancel, confirm: _vm.confirm },
      }),
      _c("yd-keyboard", {
        ref: "keyboard",
        attrs: { callback: _vm.checkPwd, disorder: "", title: "" },
        model: {
          value: _vm.show_keyboard,
          callback: function ($$v) {
            _vm.show_keyboard = $$v
          },
          expression: "show_keyboard",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }